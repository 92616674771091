import React from "react"
import { css } from "@emotion/react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { rhythm } from "../utils/typography"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
          totalCount
          edges {
            node {
              id
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                linkLabel
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `)
  return (
    <div
      css={css`
        margin: 0 auto;
        max-width: 700px;
        padding: ${rhythm(2)};
        padding-top: ${rhythm(1.5)};
      `}
    >
      <Helmet>
          <meta charSet="utf-8" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
      <h1 css={css`
          margin: 5px;
          margin-left: 0px;
          display: inline-block;
          font-style: normal;
        `}>
        {data.site.siteMetadata.title}
      </h1>

      {data.allMarkdownRemark.edges.map(({ node }) => (
        <Link
          to={node.fields.slug}
          css={css`
          float: right;
          margin: 5px;
        `}
        >
          {node.frontmatter.linkLabel}
        </Link>
      ))}

      {children}
    </div>
  )
}
